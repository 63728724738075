import actions from './actions.js';
import mutations from './mutations.js';

export default {
  state() {
    return {
      token: null,
      userId: null,
      tokenExpiration: null,
    };
  },
  getters: {
    userId(state) {
      return state.userId;
    },
    token(state) {
      return state.token;
    },
    tokenExpiration(state) {
      return state.tokenExpiration;
    },
    isAuthenticated(state) {
      return !!state.token;
    },
    state(state) {
      return state;
    },
  },
  mutations,
  actions,
};
